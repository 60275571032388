import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import Layout from '../components/layout/Layout2';
// import SEO from "../components/seo";

const Imprint = () => {
  return (
    <Layout>

    <div className="w-full container mx-auto text-white p-10 sm:p-1 md:p-4 lg:p-2 text-left text-justify break-words">
        <p className="font-bold pb-20 text-5xl">Impressum</p>
        <p className="pb-10 font-bold">simpleheroes</p>
        <p className="pb-5">Eine Unternehmung der Seizure Hops UG (haftungesbeschränkt)</p> 
        <p>Moltkestraße 42 12203 Berlin</p>
        <p>Telefon: +49 176 47 68 44 77</p>
        <p>eMail: kontakt@simpleheroes.de</p>
        <p className="pb-5">web: www.simpleheroes.de</p>
        <p>HRB 13147 CB Amtsgericht Cottbus</p>
        <p>UStID: DE 314164707 </p>
        <p className="pb-10">GF: The Don Tran</p>

        <section className="w-full text-left">
            <h4 className="font-bold text-2xl pt-5">Herausgeber, inhaltliche Verantwortlichkeit und rechtlicher Hinweise nach §5-§6 TMG (Telemediengesetz):</h4>
            <p>Seizure Hops UG (haftungsbeschränkt), Long Pham</p>
            <p>Die Kennzeichnungspflicht von Internetseiten wird im Telemediengesetz (TMG) spezifiziert. Der Gesetzestext ist unter www.gesetze-im-internet.de einsehbar (veröffentlicht vom Bundesministerium der Justiz, Berlin).</p>
            <h4 className="font-bold text-2xl pt-5">Urheberrechtshinweise</h4>
            <p>Die Inhalte dieser Website werden mit größtmöglicher Sorgfalt recherchiert und aktualisiert. Gleichwohl übernimmt der Anbieter keine Haftung für die Richtigkeit, Vollständigkeit und Aktualität der bereitgestellten Informationen. Haftungsansprüche gegen den Anbieter sind grundsätzlich ausgeschlossen, sofern seitens des Autors kein nachweislich vorsätzliches oder grob fahrlässiges Verschulden vorliegt. Die Informationen, die auf dieser Website bereitgestellt werden, sind allgemeiner Art und unverbindlich. Für verbindliche Auskunft nehmen Sie bitte direkten Kontakt mit dem Anbieter auf.</p>
            <h4 className="font-bold text-2xl pt-5">Haftungshinweis für Verweise und Links</h4>
            <p>Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich. Der Anbieter erklärt hiermit ausdrücklich, dass zum Zeitpunkt der Verlinkung keine illegalen Inhalte auf den zu verlinkenden Seiten erkennbar waren. Auf die aktuelle und zukünftige Inhalte der verlinkten Seiten, hat der Anbieter keinerlei Einfluss.</p>
            <h4>Rechtswirksamkeit</h4>
            <p>Sollten Teile oder einzelne Formulierungen dieses Textes der geltenden Rechtslage nicht, nicht mehr oder nicht vollständig entsprechen sollten, bleiben die übrigen Teile des Dokumentes in ihrem Inhalt und ihrer Gültigkeit davon unberührt.</p>							
        </section>
    </div>
    </Layout>
  );
};

export default Imprint;